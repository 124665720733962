@import url('src/assets/styles/_fonts.css');
@import url('src/assets/styles/reset.css');

html,
body,
#__nuxt,
#__layout {
  background: $background;
  font-family: 'Inter', DINNextLTPro, SourceSansPro, sans-serif;
}
.style-font {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 24px;
  color: #777e8b;
}

@media screen and (min-width: 1408px) {
  .container {
    max-width: 1200px !important;
  }
}

::-webkit-scrollbar {
  width: 1em;
  border-radius: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 0.3em;
}

::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 0.3em;
}

.is-full-width {
  width: 100%;
}

a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

p {
  margin-bottom: 1em;
}

.title-link {
  color: $primary;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.3rem;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    color: $primary;
  }
}

.container {
  @media screen and (max-width: $desktop) {
    padding: 0 0.75em;
  }
}

.dropdown-content {
  min-width: 100% !important;
  max-width: 100% !important;
}

.titleGraphic {
  margin-top: 0;
  padding: 0.8em 1.2em;
  border-bottom: $card-border;
  color: $primary;
  border-radius: 0;
  font-weight: 600;
  font-size: 18px;
}

.flex {
  display: flex !important;
  align-items: center !important;
}

.flex-column {
  display: flex !important;
  align-items: center !important;
  flex-direction: column;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.no-margin {
  margin: 0 !important;
}

.wrap {
  flex-wrap: wrap;
}

.back {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.margin-default {
  margin-bottom: 1.2em;
}

.data_value {
  font-size: 18px;
  font-weight: 500;
}

.typeNumber {
  font-size: 17px !important;
  font-weight: 500;
}

.h3 {
  font-weight: 500;
  font-size: 15px;
  background-color: $primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 1.2em;
}

p {
  margin-bottom: 0;
}

.divDataRow {
  border-bottom: 1px solid #d2d2d2;
}

.pointer:hover {
  cursor: pointer;
  background-color: #fcfcfc;
}

.no-border {
  border: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-b {
  padding-bottom: 0 !important;
}

.no-margin-b {
  margin-bottom: 0 !important;
}

.no-padding-t {
  padding-top: 0 !important;
}

.no-margin-t {
  margin-top: 0 !important;
}

.filterSelect {
  .icon-inline {
    margin-right: 0 !important;
    margin-left: 0.5em;
    width: 16px;
  }
}

.separator {
  color: #e0e4e7;
  height: 1px;
  margin: 0;
}

.container-certificates {
  padding: 1em;
  padding-left: 1.5em;
  background-color: #e6f9ff;
  color: #0086be;
  font-weight: 600;
  font-size: 1.1em;
  text-decoration: underline;
}

.link-certificate {
  color: #0086be;

  &:hover {
    color: #0086be;
  }
}

.unavailable {
  opacity: 0.3;
  pointer-events: none;
}

.invoices-container {
  padding: 1em;
  padding-left: 1.5em;
  background-color: #f8f8f9;
  color: #959699;
  font-size: 1.05em;
  font-weight: 500;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #e0e4e7;
}

.link-invoice {
  color: #959699;
  text-decoration: underline;
}

.invoice-price {
  color: #959699;
  font-weight: 600;
}

.card-blue {
  border-color: #b5dce8 !important;
  background-color: #e6f9ff;
}

.inputFilePadding {
  .input-wrapper {
    background-color: white;
  }

  .upload-draggable {
    padding: 1rem !important;
  }
}

.transaction-data-detail {
  border-bottom: 1px solid #e0e4e7;

  &:last-child {
    border-bottom: 0;
  }
}

.sides-no-padding {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
  width: auto;
}

.media-separator {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  height: 1px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.account-content-margin {
  margin-top: 47px;
}

.account-table-margin {
  margin-top: 15px;
}

.ant-input-affix-wrapper,
.ant-select-selector,
.ant-input,
.ant-picker,
.ant-input-number,
.ant-pagination-item-link,
.ant-pagination-item,
.ant-modal-content,
.ant-modal-header,
.ant-btn,
.ant-table-filter-dropdown {
  border-radius: 10px !important;
}

.ant-select-dropdown {
  border-radius: 5px !important;
}

.ant-input-number-group-addon {
  background-color: white;
  border: none;
}

.text-color-primary {
  color: #1890ff;
}

.float-rigth {
  float: right;
}

.float-left {
  float: left;
}

.radius-100 {
  border-radius: 100px !important;
}

.centered-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 10px;
}

.select-padding {
  padding: 0 5px 0 5px;
}

.ant-input-group-addon {
  border: none;
  background-color: white;
}

.ant-checkbox-wrapper {
  margin-left: 8px !important;
}


.ignore-all {
  all: unset;
  &::hover {
    all: unset;
  }
}

.bold-filter-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: #021120;
}
