.content h3 {
  line-height: 1.2
}

.not-content-yet {
  color: #C7CBD4;
  opacity: 0.71;
  font-weight: 700;
  font-size: 18px;
  padding: 1.25rem;
  padding-bottom: 2.5rem
}

.num {
  width: 1.6em;
  font-size: 0.9em;
  color: $primary;
  border-radius: 0.4em;
  text-align: center;
  font-weight: 700;
  padding: 0.15em;
  padding-left: 0.4em;
  padding-right: 0.4em;

}

@media (max-width:"1024px") {
  .num {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbar-start {
  margin: 0 auto;

}

.titulo {
  margin-bottom: 0 !important
}

.data-resume-block {
  border-radius: 0 !important;
  width: 30%;
  margin-right: 1.2em;
  padding: 1em;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    transition: all .5s;

    box-shadow: 0 2px 6px rgba(41, 149, 201, 0.27);
  }
}

.admin-analytics {
  .data-resume-block {
    width: 30%;
    margin-right: 0;
  }
}

.dataValue {
  font-size: 15px;
  font-weight: 600;
}

.dataValueTitle {
  font-weight: 600;
  font-size: 22px;
}

.data-title {
  font-size: 15px;
}

.data-resume-block-active {
  background: #E6F9FF;
  border-color: #B5DCE8;
  cursor: auto;

  &:hover {
    transform: unset;
    box-shadow: unset;
  }

  .data-title {
    color: $primary !important;
    opacity: 0.6;
  }

  .dataValueTitle {
    color: $primary !important;
  }

  .value-media {
    color: $primary !important
  }

  .has-text-grey {
    color: $primary !important;
    opacity: 0.7;
  }
}

.resume-data-container {
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 6px rgba(25, 25, 25, 0.27);
  }

  margin: 0 !important;
  margin-bottom: 1.2em;

  &:last-child {
    margin-bottom: 0
  }

  .row-resume-data {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: all 0.1s;

    &:hover {
      cursor: pointer;
      background-color: $blue-light;

      p {
        color: $primary;
      }

      span {
        color: $primary !important;
      }
    }
  }
}

.full-width {
  width: 100% !important;
}

.integration-option-container {
  width: 100%;
  border-radius: 5px;
  min-width: 400px;
}

.option-title {
  font-size: 18px;
  font-weight: 600;
  color: $primary;
  padding-bottom: 0.75rem
}

.option-title-text {
  font-size: 15px;
  font-weight: 500;
  color: #2B2E33
}

.card2 {
  width: 100% !important;
  background: white;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  border-radius: 3px;
  border: $card-border;
  margin-bottom: 65px !important;
}

h1 {
  margin-bottom: 0.5em;
}

.my-container {
  height: auto;
  min-height: 100%;
}

.success-modal {
  padding-bottom: 0.5em;
  width: 64px;
  align-self: center;
}

.failed-modal {
  padding-bottom: 0.5em;
  width: 64px;
  align-self: center;
}

.notification-circle {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #ED5F55;
  margin-right: 9px;
  margin-left: 9px;
}

.notification-empty {
  width: 24px;
}

.admin-margin {
  margin-top: 0;
}

.project-detail-purchase-card {
  .input-suffix.danger-input {
    border-color: $red !important;
  }
}

.data-title-small {
  color: #c7cbd4;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
}

.data-value-title-small {
  font-weight: 600;
  color: black;
  font-size: 20px;
  padding-bottom: 0.7em;
  border-bottom: 1px solid #E0E4E7;
}

.titleCard2 {
  border-bottom: 1px solid #E0E4E7;
  padding: 1.2rem;
  font-weight: 600;
  font-size: 18px;
  color: $primary;
}

.more-info {
  color: $primary;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-top: 1em;
  text-decoration: underline;
}

a {
  text-decoration: none;
}

.top-right {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 15px;
}

.top-left {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 15px;
}

.card-content-project-card {
  padding: 1rem
}

.menu-text-small {
  font-size: 1.12em;
  text-decoration: none;
  color: #2B2E33;
  font-weight: 500;
}

.dropdown.is-disabled {
  opacity: 1;
  cursor: unset;
}

.projects-dashboard-integrations {

  .table td,
  .table th {
    padding: 0.80em 0.60rem
  }

  .table thead {
    display: none
  }
}

.compensation-title-number {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: $primary;
  background: white;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin-right: 1em;
}

.compensation-title-number-active {
  background: $primary !important;
  color: white !important
}

.compensation-container {
  width: 802px !important;
}

.compensation-content {
  width: 100%;
  background: #F8F8F9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.label-compensation {
  color: #2B2E33;
  font-size: 16px;
  font-weight: 600;
  margin-right: 1em;
  text-align: left;
}

.input-compensation {
  outline: none;
  -webkit-appearance: none;
  border-color: #A5ADB8;
  border-radius: 5px;
  height: 40px;
}

.compensation-data-container {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  p {
    width: 150px
  }

  input,
  select {
    width: 350px
  }
}

.compensation-header {
  .ant-select-selection {
    border: 0 !important
  }

  .ant-select-selection-selected-value {
    color: #2b2e3388;
    font-size: 15px;
    font-weight: 600;
  }

  .ant-select-arrow {
    top: 40% !important;
    opacity: 0.5;
    right: 7px;
  }
}

@media (max-width: 850px) {
  .compensation-container {
    width: 100% !important;
  }

  .button-compensation {
    min-width: 40% !important;
    margin-bottom: 1.2em;
  }

  .compensation-data-container,
  select {
    width: 400px;

    p {
      width: 100px
    }
  }
}

.more-info-project {
  .modal-card {
    max-width: 510px !important;
    width: 510px !important;
  }
}

@media (max-width: 1220px) {
  .projects-row {
    min-width: 50%;
    max-width: 50%;
  }
}

@media (max-width: 620px) {
  .projects-row {
    min-width: 100%;
  }
}

.currencyLoading {
  background-color: transparent !important;
  border: 1px solid #d9d9d9 !important;
  height: 32px !important;
  padding-left: 2em;
  padding-right: 2em;
}

.button.is-primary.is-loading.currencyLoading::after {
  border-color: transparent transparent #d9d9d9 #d9d9d9 !important;
}

.button.is-primary.is-loading.currencyLoading.big::after {
  border-color: transparent transparent #d9d9d9 #DCDCDC !important;
  width: 2.5em;
  height: 2.5em;
  left: auto;
  top: auto;
}

.button.is-primary.is-loading.currencyLoading.big {
  border: 0 !important;
  margin-right: 1em;
}

.logo-header-compensation {
  width: 200px;
}

.project-card-container-small {
  position: relative;
  padding-top: 52%
}

.project-card-container-small:focus {
  outline: none;
}

.project-item-small:focus {
  outline: none;
}

.project-item-selected {
  background-color: #E6F9FF !important;
  border: 2px solid $primary;
}

.filter-selector-container {
  .ant-select-selection {
    border: none !important;
    box-shadow: none;
  }
}

@media (max-width: 350px) {
  .compensation-layout {
    min-height: 100%;
  }

  body {
    background-color: white;
    border: 1px solid #E0E4E7;

  }

  .project-item-selected {
    border: 4px solid $primary;
  }

  .compemsation-layout-container {
    margin: 0 auto;
    margin-bottom: 0;
    min-width: 100%;
    padding: 0;
    width: 100%;
    background-color: white;
    line-height: 1.2;

    .project-item {
      width: 100%;
      margin-right: auto !important;
    }

    &>.card-content {
      padding: 0;
      padding-bottom: 1rem
    }
  }

  .compensation-header {
    margin-top: 0
  }

  .compensation-layout-content {
    padding: 0 !important
  }

  .compensation-header-summary {
    display: none !important;
  }

  .logo-header-compensation {
    width: 115px;
  }

  .compensation-header .ant-select-selection-selected-value {
    font-size: 12px;
  }

  .flex-space-between.compensation-container.compensation-header {
    padding-left: 0.5rem;
    padding-right: 0.3rem;
  }

  .ant-select-selection__rendered {
    margin-left: 0;
  }

  .compensation-container {
    border-radius: 0;
    border: 0;

    &>.card-content {
      padding: 0;
      padding-bottom: 1rem
    }
  }

  .compensation-data-container {
    flex-direction: column;
    margin: auto;
    padding-top: 1rem;
    align-items: flex-start !important;
    max-width: 100%;
  }

  .compensation-data-container input,
  .compensation-data-container select {
    width: 100%;
  }

  .input-compensation-small {
    max-width: 100%;
    width: 100%;
  }

  .compensation-title {
    text-align: left;
    font-size: 16px;
  }

  .compensation-subtitle {
    font-size: 14px !important;
  }

  .compensation-title-number {
    display: none;
  }

  .label-compensation {
    font-size: 14px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
  }

  .price-project-card {
    color: white !important;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    font-weight: 700
  }

  .code-project-card {
    color: white !important;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    font-weight: 500
  }

  .top-right {
    margin: 10px;
  }

  .project-card-title {
    height: auto !important;
    margin-bottom: auto !important;

    .text-title {
      color: white !important;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
      font-weight: 700
    }
  }

  .project-item-small {
    width: 100%;
    margin-bottom: 1.2em;
    border-radius: 15px;
  }

  .more-info {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    width: fit-content;
  }

  .ant-select-dropdown-menu-item {
    padding: 2px 4px
  }

  .is-wide {
    min-width: 135px !important;
  }

  .projects-view-buttons {
    padding-left: 0.1rem !important;
    padding-right: 0.1rem !important;
  }

  .data-small-container {
    background: transparent !important;
    padding: 0 !important;
    height: auto !important;
    width: auto !important;
  }

  .more-info-project {
    .modal-card {
      max-width: 300px !important;
      width: 300px !important;
    }

    .more-info-title {
      width: auto !important;
    }
  }

  .projects-view-buttons {
    padding-left: 0 !important;
    padding-right: 0 !important
  }
}

.projects-list-view-container {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #CCD3D8;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(204, 211, 216, 0.4);
    box-shadow: none;
  }
}

.project-detail-purchase-card {
  .data-required {
    color: #0086BE
  }
}

.subtitle {
  color: $primary;
  font-size: 16px;
  font-weight: 700;
}

code {
  color: #2B2E33;
  font-size: 14px;
  padding: 0.75em;
  border-radius: 5px;
}

.card-title {
  padding: 0.9rem 1.25rem
}

.availableTonesContainer {
  .progress {
    height: 10px;
  }
}

.table-header {
  display: flex;
  padding: 1.2em;
  border-bottom: 1px solid #E0E4E7;
}

.elementsHistory {
  font-weight: 600;
  width: 10%;
  padding: 5px;
  word-wrap: break-word;
}

.padding-sides {
  padding: 0 1.25rem;
}

.no-thead {
  .table thead {
    display: none;
  }
}

.projects-detail-price-edit-project-view {
  .column-is-6 {
    width: 100% !important
  }
}