/************
  COLORS
 ************/
$white: #fff;
$white-ter: #F1F1F5;
$shadow-color: #E2E2EA;

$grey-lighter: #A5ADB8;
$grey-light: #959699;
$grey-darker: #2B2E33;
$yellow-dark: #F5BA42;
$red-dark: #D8434E;
$cyan: #2995C9;
$blue: #659FB2;
$red: #ED5F55;
$green: #43CB6F;

$blue-light: #E6F9FF;
$dark: rgb(39, 43, 52);

$primary: #003A6C;
$info: $blue;
$info-light: $blue-light;

/************
  FONTS
 ************/
$family-primary: 'Poppins',
'Helvetica Neue',
'Helvetica',
'Arial',
sans-serif;
$body-size: 14px;

$size-7: 0.8571428571rem; // 12px

/************
  BORDERS
 ************/
$radius-small: 5px;
$radius: 5px;
$radius-large: 10px;


/************
  CARDS
 ************/
$card-shadow: none;
$card-border-width: 1px;
$card-border: $card-border-width solid $shadow-color;
$card-border-radius: $radius-large;
$card-content-padding: 1.5rem;

/************
  INPUTS
 ************/
$input-shadow: none;
$input-focus-color: $primary;
$input-focus-border-color: $primary;
$input-focus-box-shadow-size: 0;

$control-height: 2.86em;

/************
  LINKS
 ************/
$link: $grey-darker;
$link-visited: $grey-darker;
$link-hover-border: none;
$link-focus-border: none;

/************
  HEADER
 ************/
$navbar-box-shadow-size: inset 0 -1px 0;
$navbar-box-shadow-color:  $shadow-color;
$navbar-height: 5.7142857143rem;  // 80px. Needs to be in rem because of some calculation made by Bulma based on this variable
$navbar-item-hover-color: $primary;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $primary;
$navbar-dropdown-item-hover-color: $primary;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-color: $primary;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-border-top: none;
$navbar-dropdown-radius: none;
$navbar-dropdown-boxed-shadow: none;

/************
  BOX
 ************/
$box-radius: $radius;
$box-shadow: none;
$box-padding: 5px .75em;

/************
  TABLE
 ************/
$table-cell-border: 1px solid $shadow-color;
$table-head-cell-border-width: 0 0 1px;

/************
  MODALS
 ************/
$modal-card-head-background-color: $white;
$modal-card-head-border-bottom: 1px solid $shadow-color;
$modal-card-title-color: $primary;

/************
  CAROUSEL
 ************/
$carousel-arrow-background: none;
$carousel-arrow-color: $white;
$carousel-indicator-border: $white;
$carousel-indicator-color: transparent;

/************
  OTHER
 ************/
$hr-background-color: $shadow-color;



@import '~bulma/sass/utilities/all';

$help-size: $size-normal;
$control-padding-vertical: calc(0.64em - #{$control-border-width});
$control-padding-horizontal: calc(0.86em - #{$control-border-width});
