.dark {
  @media screen and (min-width: 1408px) {
    .container {
      max-width: 1408px !important;
    }
  }

  .table-header {
    border-color: #4f5467
  }

  .spacerToolbar {
    width: 100%;
    border-bottom: 1px solid #4f5467;
    display: flex;
    align-self: stretch;
  }

  .titleTable {
    border-bottom: 1px solid #4f5467;
  }

  .rowTransactionOdd {
    background-color: #4a4a4a;
  }

  .rowTransactionBorder {
    border-bottom: 1px solid #41444f;
  }

  a {
    color: $primary
  }

  font-size: 1.01em;

  .card-image {
    margin: -1em;

    img {
      border-radius: 0 !important
    }
  }

  .dark-panel {
    background: #1d2126;

    .navElement {
      color: white;
      opacity: 0.6;
    }

    .navElement:hover {
      box-shadow: inset 0 -2px 0 #27A0DC;
      opacity: 1;
    }

    .navElement:active {
      box-shadow: inset 0 -2px 0 #27A0DC;
      opacity: 1;
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.6;
    color: #b2b9bf !important;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.6;
    color: #b2b9bf !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    opacity: 0.6;
    color: #b2b9bf !important;
  }

  .filterSelect {
    background: #272b34 !important;
    border: 0;
    border-radius: 0
  }

  .actionButtons {

    .navbar-item,
    .navbar-link {
      color: #b2b9bf
    }
  }

  header {
    background: #272b34 !important;

    .navbar {
      background: #272b34
    }
  }

  .actionButtons {
    .navbar-item, .navbar-link:hover {
      color: $primary
    }
  }

  color: white;

  .menu-title {
    color: #fff !important;
    opacity: 1;
  }

  .menu-content {
    opacity: 0.6;
    color: #b2b9bf !important;
  }

  .menu-text {
    opacity: 0.6;
    color: #b2b9bf !important;
  }

  .menu-text:hover {
    opacity: 1;
    color: $primary !important;
  }

  header {
    color: #b2b9bf;
    box-shadow: inset 0 -2px 0 #4f5467 !important;
  }

  .navbar.has-shadow {
    box-shadow: inset 0 -2px 0 #4f5467;
  }

  .navbar-item {
    color: #b2b9bf;
    box-shadow: inset 0 -2px 0 #4f5467;

    svg {

      g,
      path {
        fill: #b2b9bf;
      }
    }
  }

  .navbar-item:hover {
    color: #27A0DC;
    box-shadow: inset 0 -2px 0 $primary;
    svg {
      g, path {
        fill: $primary;
      }
    }
  }

  .menu-list .is-active {
    .menu-text {
      color: $primary !important;
      opacity: 1;
    }
  }

  .card2,
  .card {
    background: #272b34 !important;
    border: 0 !important;
  }

  .card2 {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4);
  }

  .b-table {
    background: #272b34 !important;
  }

  .table {
    background: #272b34 !important;
  }

  .card {
    background: #272b34 !important;
    border: 0
  }

  .is-active {
    color: white;
    background-color: rgba(50, 55, 67, 0.7) !important;

  }

  .table thead td,
  .table thead th {
    color: #b2b9bf !important
  }

  .table td,
  .table th {
    color: #a1aab2 !important;
  }

  td {
    font-weight: 500;
  }

  table,
  th,
  td {
    border-color: #4f5467
  }

  .top-projects-table {
    border-top: 0
  }

  .titulo {
    color: #b2b9bf !important;
    opacity: 1 !important;
  }

  .elementsDefault {
    opacity: 0.6;
    color: white !important;
  }

  .data_value {
    font-size: 18px;
    color: #b2b9bf;
  }

  .rowTransaction {
    background: #272b34 !important;
    border-color: #4f5467
  }

  .elements {
    opacity: 0.6;
    color: white !important;
    font-size: 1.03em;
  }

  .card {
    color: #b2b9bf !important;
  }

  .filterItem {
    font-size: 1.03em !important;
  }

  .button.is-primary {
    background-color: transparent;
    border-color: $primary;
    color: $primary;
  }

  .button.is-primary:hover {
    background-color: $primary;
    border-color: $primary;
    color: #fff
  }

  .input-wrapper {
    border: 0 !important
  }

  .table.is-striped tbody tr:not(.is-selected):nth-child(even) {
    background-color: transparent
  }

  .bank-data__key {
    color: #b2b9bf;
  }

  .data-resume-block {
    .dataValueTitle {
      color: #b2b9bf;
    }

    .data-title {
      color: #7a7a7a;
      opacity: 0.6;
    }

    .value-media {
      color: #7a7a7a
    }
  }

  .titleGraphic {
    color: $primary !important;
    border-bottom: 1px solid #4f5467;
  }

  .resume-data-container .row-resume-data:hover {
    background-color: #323743 !important
  }

  .modal-card-head,
  .modal-card-foot,
  .modal-card-body {
    background-color: #323743 !important
  }

  .menu-list .is-active {
    color: $primary !important;
    opacity: 1;
  }

  .menu-content:hover {
    color: $primary !important;
    opacity: 1;
  }

  .activeNav {
    box-shadow: inset 0 -2px 0 $primary
  }

  .divDataRow {
    border-bottom: 1px solid #4f5467;
  }

  hr {
    background-color: #4f5467
  }

  footer {
    background-color: #272b34 !important
  }

  .activeNav {
    color: #27A0DC !important;
    box-shadow: inset 0 -2px 0 #27A0DC;
    opacity: 1 !important;
  }

  .my-container {
    height: auto;
    min-height: 100%;
  }

  .card {
    background: #272b34 !important;
    border-radius: 0;
    border: 0
  }

  input {
    background: transparent !important;
    border: 0 !important;
    border-radius: 0;
    border-bottom: 1px solid #4f5467 !important;
    color: #a1aab2;
  }

  .select.is-fullwidth.is-empty select,
  .dropdown.dropdown-menu-animation.is-mobile-modal.is-expanded {
    background-color: rgba(39, 43, 52, 0.5) !important;

    span {
      color: rgba(122, 122, 122, 0.7)
    }

    button {
      background-color: rgba(39, 43, 52, 0.5) !important;

      span {
        color: rgba(122, 122, 122, 0.7)
      }
    }
  }

  .filterTransactionsCard {
    background-color: transparent !important;
    border: 0 !important;

    .dropdown-content,
    select,
    .ant-select-selection {
      background-color: rgb(39, 43, 52) !important;
      border-color: $primary;
      color: $white;

      .ant-select-arrow {
        color: $primary;
        font-size: 14px;
      }

      .ant-select-selection__clear {
        color: #27A0DC;
        font-size: 17px;
        width: 20px;
        background-color: rgb(39, 43, 52);
        opacity: 1;
        top: 44%;
        right: 9px;
      }
    }

    .ant-calendar-picker-input.ant-input {
      background-color: rgb(39, 43, 52) !important;
      border-color: $primary;
    }

    .ant-calendar-range-picker-input {
      border-bottom: none !important;

      &::placeholder {
        color: $white;
        opacity: 1;
      }
    }

    .ant-calendar-range-picker-separator {
      color: $primary;
    }

    .ant-calendar-picker-clear {
      color: $primary;
      font-size: 17px;
      width: 20px;
      background-color: rgb(39, 43, 52);
      opacity: 1;
      top: 48%;
      right: 9px;
    }

    .icon-inline {
      color: #27A0DC;
    }
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    background-color: rgba(39, 43, 52, 0.5) !important;
  }

  .container.container-text .field.is-grouped .control.has-icons-left {
    input {
      background-color: rgba(39, 43, 52, 0.5) !important
    }
  }

  .full-search {
    border-bottom: 0 !important;
    background: #323743 !important
  }

  form {
    background: transparent !important;

    .field {
      background: transparent !important;

      .control {
        background: transparent !important;
      }
    }
  }

  .icon {

    g,
    path {
      fill: #b2b9bf;
    }
  }

  .title {
    color: #c7cbd4
  }

  h1 {
    &.title {
      color: $primary
    }
  }

  .has-text-weight-bold {
    color: #7a7a7a
  }

  .messageViewRow {
    color: #b2b9bf !important;
  }

  .msgData {
    color: #b2b9bf !important;
  }

  .label {
    color: #7a7a7a !important;
  }

  .pointer:hover {
    background: #2b2f38
  }

  .total-order {
    background-color: transparent !important;
    border-top: 1px solid #4f5467;
    border-bottom: 1px solid #4f5467;
  }

  p {
    color: white;
  }

  .has-text-grey-lighter {
    color: white !important;
    opacity: 0.6;
  }

  .title-card {
    border-bottom: 1px solid #4f5467 !important;
  }

  .columnTitle {
    font-weight: 600 !important;
    font-size: 1.05em !important;
    color: #b2b9bf !important;

    p {
      color: #b2b9bf !important;
      font-weight: 600 !important;
      font-size: 1.05em !important;
    }
  }

  .messageViewRow {
    .fields {
      color: #a1aab2 !important;
      font-size: 1.03em;
      color: #31343b;

      p {
        color: #a1aab2 !important;
        font-size: 1.03em;
        color: #31343b
      }
    }
  }

  .modal-card-head {
    border-bottom: 1px solid #4f5467 !important;
    border-radius: 0 !important
  }

  .modal-card-body {
    border-radius: 0 !important
  }

  .notification-circle {
    width: px;
    height: 8px;
    border-radius: 4px;
    background-color: #ED5F55;
    margin-right: 9px;
    margin-left: 9px;
  }

  .notification-empty {
    width: 26px;
  }
}

.dark-dropdown {
  background-color: rgb(39, 43, 52) !important;
  border-color: $primary;
  color: $white;

  li {
    color: $white;

    &:hover {
      color: $grey-darker;
    }
  }

  .ant-select-dropdown-menu-item-selected {
    background-color: $primary;
  }

  .ant-select-dropdown-menu-item-active {
    background-color: $primary;
    &:hover {
      background-color: $primary;
      color: $grey-darker;

      .icon-inline {
        color: $grey-darker;
      }
    }
  }

  .icon-inline {
    color: $primary;
  }
}

.dark-dropdown-range-picker {

  .ant-calendar {
    background-color: rgb(39, 43, 52);
    color: $white;
    border-color: $primary;
  }

  .ant-calendar-range {
    background-color: rgb(39, 43, 52);
  }

  .ant-calendar-range .ant-calendar-input,
  .ant-calendar-range .ant-calendar-time-picker-input {
    background-color: rgb(39, 43, 52);
    color: $white;
  }

  .ant-calendar-input-wrap {
    border-bottom: 1px solid $primary;
  }

  .ant-calendar-range-middle {
    color: $primary;
  }
  .ant-calendar-body  {
    border-top: 1px solid $primary;
  }

  .ant-calendar-header {
    a {
      color: $white;
    }

    a:hover {
      color: #1890ff;
    }
  }

  .ant-calendar-month-panel,
  .ant-calendar-year-panel,
  .ant-calendar-decade-panel {
    background-color: rgb(39, 43, 52);
  }
  .ant-calendar-month-panel-body, .ant-calendar-year-panel-body, .ant-calendar-decade-panel-body {
    border-top: 1px solid $primary;
  }

  .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-decade:hover {
    color: $white !important;
    background-color: #1890ff !important;
  }

  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
    background-color: $red-dark !important;
    color: black !important;
  }

  .ant-calendar table,
  .ant-calendar th,
  .ant-calendar td {
    color: $white;
  }

  .ant-calendar-date {
    color: $white;
  }

  .ant-calendar-date:hover {
    background-color: rgb(39, 43, 52);
    border-color: #1890ff;
  }

  .ant-calendar-last-month-cell .ant-calendar-date,
  .ant-calendar-next-month-btn-day .ant-calendar-date,
  .ant-calendar-last-month-cell .ant-calendar-date:hover,
  .ant-calendar-next-month-btn-day .ant-calendar-date:hover {
    color: $red-dark;
  }

  .ant-calendar-range .ant-calendar-in-range-cell > div {
    color: $white;
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background-color: $green;
  }

  .ant-calendar-today .ant-calendar-date {
    background-color: #1890ff;
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background-color: rgb(39, 43, 52);
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-prev-month-btn::after {
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-prev-month-btn::before {
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-prev-year-btn::after {
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-prev-year-btn::before {
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-next-month-btn::after {
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-next-month-btn::before {
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-next-year-btn::after {
    border-color: #1890ff;
  }

  .ant-calendar-header .ant-calendar-next-year-btn::before {
    border-color: #1890ff;
  }

  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
    border-color: #1890ff;
  }

  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before {
    border-color: #1890ff;
  }

  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
    border-color: #1890ff;
  }

  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {
    border-color: #1890ff;
  }

  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
    border-color: #1890ff;
  }

  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
    border-color: #1890ff;
  }
}

.darkDatePicker {
  .datepicker .dropdown-content {
    border-radius: 5px;
    background-color: $dark;
  }

  .select select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background-color: $dark;
    color: $cyan;
  }

  .select select option {
    color: #fff;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: $cyan;
  }

  .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: $cyan;
  }

  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable {
    color: $cyan;
  }

  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    background-color: $green;
    color: #fff;
  }

  .datepicker .datepicker-header {
    border-bottom: 1px solid $cyan;
  }

  .datepicker .datepicker-table .datepicker-header .datepicker-cell {
    color: $cyan;
  }

  .datepicker .datepicker-table .datepicker-cell {
    border-radius: 0px;
  }

  .datepicker-cell.is-selectable:hover {
    background-color: $cyan !important;
    color: #fff !important;
  }

}
