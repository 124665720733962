input {
  caret-color: $primary;
}

.label {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: $size-small;

  .label__main-text {
    text-transform: uppercase;
  }

  .label__right {
    font-weight: normal;
  }
}

.field .has-addons .control {
  margin-bottom: 0;
  z-index: 10;
}

.upload {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;

  .upload-draggable {
    border: 2px dashed $shadow-color;
    border-radius: $radius;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    color: $grey-light;
    font-weight: 500;

    p {
      margin: 0;
    }
  }
}

.b-checkbox.checkbox {
  display: flex !important;
}

.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.modal.has-overflow {
  position: fixed !important;
  overflow: auto !important;

  .modal-background {
    position: fixed !important; // so page stays dark as we scroll
  }

  .modal-content,
  .modal-card,
  .modal-card-body {
    overflow: visible !important;
  }
}

.data-title-transaction {
  font-weight: 700;
  color: #C7CBD4;
  font-size: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.data-content {
  font-size: 1.1em;
  font-weight: 500;
  color: #262626;
  word-wrap: break-word;
}

.labelOffer {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-size: 1em;
  text-transform: uppercase;
  color: black;
  font-weight: 500;
}

.inputField {
  width: 100%;
  border: 1px solid #A5ADB8;
  border-radius: 5px;
  height: 2.86em;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
}

.inputField.is-active {
  border-color: $primary;
}

.inputField:focus {
  border-color: $primary;
}

.inputDraggableUpload {
  .input-wrapper {
    height: 130px;
  }

  .upload {
    display: flex;
    align-items: center;
    padding-inline: 25% !important;

  }

  .upload-draggable {
    height: 100px;
    margin-inline: 30%;
  }
}

.make-offer-container {
  .label {
    margin-bottom: 5px !important;
    margin-top: 8px !important;
  }

  p.label {
    margin-bottom: 5px !important;
    margin-top: 8px !important;
  }

  textarea {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    padding: 1em;
    outline: 0;
    border: 1px solid #A5ADB8;
  }

  textarea:focus {
    border-color: $primary;
  }

  .inputField {
    border-color: #A5ADBB !important;
  }

  .inputField:focus {
    border-color: $primary !important;
  }

  p {
    margin-bottom: 0
  }

  label,
  input {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;
    outline: 0;
  }

  label::after {
    content: attr(textcontent);
    position: absolute;
    top: 2px;
    left: 83%;
    padding: 0.5em;
    width: 6.5em;
    display: block;
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  @media(max-width: 550px) {
    label::after {
      left: 79%;
    }
  }

  .total-order {
    background-color: #DDF4FB;
    color: #0087BF;
    margin: 0 (-$card-content-padding) 0.5rem !important;
    padding: 10px $card-content-padding;
    display: flex;
    justify-content: space-between
  }

  .row-order {
    color: #0087BF;
    margin: 0 (-$card-content-padding) 0.5rem;
    padding: 10px $card-content-padding;
    display: flex;
    justify-content: space-between
  }
}