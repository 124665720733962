.container > .navbar .navbar-brand {
  margin-left: 0;
  .logo-header{
    box-shadow:unset;
    img{
      width:200px;
    }
  }
}

.navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
  margin-right: 0;
}

.navbar-item {
  position: static;
}

.navbar-item, .navbar-link {
  margin: 0 20px;
  text-decoration: none;
  font-weight: 600;
  color: #2B2E33;
  @at-root .navbar-menu & {
    @media (min-width: $desktop) {
      padding: 0;
    }
  }

  img {
    max-height: 3rem;
  }
}

a.navbar-item, a.navbar-link {
  &:hover, &.nuxt-link-active, .is-active > & {
    color: $primary;
    box-shadow: inset 0 -2px 0 $primary;
  }
}

.navbar-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 40px;
  box-shadow: inset 0 -1px 0 $shadow-color;

  a.navbar-item {
    margin: 0 20px;
    padding: 0;
    color: $grey-light;

    &:hover, &.nuxt-link-active {
      box-shadow: none;
      color: $primary;
    }
  }
}
.activeNav {
  box-shadow: inset 0 -2px 0 $primary
}
