.banner_dis {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    background-color: rgb(0 62 111 / 88%);
    display: flex;
    align-items: center;
    height: 35px;
    white-space: nowrap;
    position: relative;
  }
  
  .slider_banner {
    display: flex;
    width: 100%;
    animation: slideText 50s linear infinite;
  }
  
  .text_banner {
    font-size: 10px;
    font-weight: bold;
    color: whitesmoke;
    letter-spacing: .7px;
  }

  .highlight {
    color: rgb(255, 255, 0);
  }

  .banner_dis:hover .slider_banner {
    animation-play-state: paused;
  }
  
  @keyframes slideText {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-60%);
    }
  }
  
  @media (max-width: 768px) {
    @keyframes slideTextMobile {
        from {
            transform: translateX(125%);
        }
        to {
            transform: translateX(-210%);
        }
    }

    .slider_banner {
        animation: slideTextMobile 50s linear infinite;
    }
}