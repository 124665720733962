.card {
  border-radius: $card-border-radius;
  border: $card-border;
}

.title-card {
  border-bottom: 1px solid #e0e4e7;
  padding: 1.2rem;
  padding-left: 1.5rem;
  font-weight: 600;
  font-size: 18px;
  color: $primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.text-title-card {
  font-weight: 600;
  font-size: 18px;
  color: $primary;
  padding-bottom: 0;
  border-bottom: 0
}

.title-default {
  border-bottom: 1px solid #e0e4e7;
  padding: 1.2rem;
  font-weight: 600;
  font-size: 18px;
  color: $primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0
}

.project-thumbnail {
  width: 50px;
  height: 50px;
  margin-right: 1em;
  align-items: center;
  border-radius: 5px;
}

.ant-col .ant-row .ProjectCard_module_card__79bdb06f {
  width: 288px;
  height: 312px;

  @media (max-width: 692px) {
    width: 328px !important;
    height: 224px;
  }
}
