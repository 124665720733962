.carousel {
  display: flex;
  position: relative;

  .carousel-items {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;

    .carousel-item {
      flex-shrink: 0;
      width: 100%;

      .image {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        img {
          object-fit: cover;
        }
      }
    }
  }
}

.carousel-arrow {
  .icon {
    i {
      opacity: .35;

      &:before {
        font-size: 85px;
      }
    }

    &:hover {
      border: none;

      i {
        opacity: 1;
      }
    }
  }
}


.carousel .carousel-indicator.is-inside.is-bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
  justify-content: right;
  padding-right: 20px;

  .indicator-item {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    border: 3px solid #fafafa;
    display: inline-block;
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    margin: 0 2.5px 0 2.5px;

    &:hover {
      opacity: 0.75;
    }
  }
}

.carousel .carousel-indicator .indicator-item {
  .indicator-style {
    background: transparent;
  }
}

.carousel .carousel-indicator .indicator-item.is-active .indicator-style, .carousel .carousel-indicator .indicator-item .indicator-style:hover {
  background: $white;
}

.carousel__slide {
  height: 100%;
  position: absolute;
  overflow-y: auto;
  opacity: 0;
}
