.button {
  font-weight: bold;
}

a.button {
  text-decoration: none;
}

.button.is-small {
  font-size: $size-normal;
  padding-top: 0;
  padding-bottom: 0;
  height: 2.3em;
}

.button.is-wide {
  min-width: 220px;
}

.button.input-text-addon {
  border-left: 0;
}

button.delete {
  border-radius: $radius;
  background: $white-ter;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  &:hover {
    background: $shadow-color;
  }
  &:before, &:after {
    background-color: $dark;
    border-radius: 2px;
  }
}
