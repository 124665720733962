.b-table .table th {
  font-weight: $weight-bold;
}
.table td,
.table th {
  padding: 0.80em 0.60rem;
  color: #31343b;
  font-size: 1.03em;
}
.table th{
  font-size: 1.05em;
}
.b-checkbox.checkbox .control-label {
  padding-left: unset !important;
}
.b-checkbox.checkbox:not(.button) {
  margin-right: unset !important;
}

.modal.has-overflow {
  position: fixed !important;
  overflow: auto !important;
  .modal-background {
    position: fixed !important;
  }
  .modal-content {
    overflow: visible !important;
  }
  .modal-card {
    overflow: auto !important;
  }
  .modal-card-body {
    overflow: auto !important;
  }
}
.modal .animation-content .modal-card {
  overflow: auto !important;
}
.rowTransaction {
  border: solid #e0e4e7;
  border-width: 0 0 1px;
  align-items: center;
}

.b-table {
  .table {
    td {
      vertical-align: middle;
    }
  }
}
