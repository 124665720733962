.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.hyphenate {
  /* Careful, this breaks the word wherever it is without a hyphen */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Adds a hyphen where the word breaks */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.has-text-weight-bold {
  font-weight: 500 !important;
}
.has-text-500 {
  font-weight: 500;
}
.is-small.input {
  font-size: unset !important;
}
.search {
  border-color: $grey-lighter
}
.search::placeholder {
  color: #2B2E33;
  opacity: 0.5;
}

.search:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #2B2E33;
  opacity: 0.5;
}

.search::-ms-input-placeholder { /* Microsoft Edge */
  color: #2B2E33;
  opacity: 0.5;
}
.has-text-grey {
  font-size: 1.03em;
  color: #959699;
  font-weight: 400
}


.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

.is-size-5 {
  font-size: 1.2rem !important;
}

.msgData {
  display: flex;
  width: 80%;
  text-align: left;
  font-size: 15px;
  color: #2B2E33;
  font-weight: 600;
}

.titleContent {
  width: 20%;
  color: #47494e;
  display: flex;
  align-content: flex-start;
  font-size: 15px;
  font-weight: 500;
}

.headerMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerMessageSubject {
  display: flex;
  color: $primary;
  font-size: 19px;
  font-weight: 600;
}

.headerMessageDate {
  color: #959699;
  font-size: 17px;
}
