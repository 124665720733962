.menu-list a.is-active {
    background-color: $blue-light;
    color: $primary;
    box-shadow: inset -2px 0 0 $primary;
    text-decoration: none;
}

.menu-list a {
  font-size: 1.05rem;
  border-radius: 0px;
  color: $grey-darker;
  display: block;
  padding: 0.5em 0.75em;
  padding-left: 1.2em;
  font-weight: 500;
  text-decoration: none;

  &:hover, .is-active > & {
    color: $primary;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 $primary;
  }
}
.menuContainer {
  padding-bottom: 1rem;
}
