.integration-card {
  background-color: #F8F8F9;
  border-radius: 12px;
  width: 265px;
  height: 177px;
  padding: 1em;
  display: flex;
  margin-right: 1.8rem;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.75em;
  cursor: pointer;
  :last-child {
    margin-right: 0
  }
}
.integration-card-empty {
  margin-right: 1.5rem;
  border-radius: 12px;
  width: 265px;
  height: 177px;
  display: flex;
  justify-content: center;
  border: 1px solid $primary;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.75em;
}
.integration-card-spacer {
  max-width: 25%;
  width: 265px;
  height: 177px;
  margin-bottom: 1.75em;
}

.integrations-container {
  padding: 1.2em 1.5em;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.integration-card-title {
  color: $primary;
  font-weight: 600;
  font-size: 18px;
}

.integration-card-subtitle {
  color: #959699;
  font-weight: 500;
  font-size: 14px;
}
.integration-card-footer-small-title {
  color: #C7CBD4;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.integration-card-footer-title {
  color: #2B2E33;
  font-size: 16px;
  line-height: 1.25;
  padding-bottom: 0.5em
}
.integrations-container {
  .progress {
    height: 5px;
  }
}

.new-integration-title {
  color: $primary;
  font-weight: 600;
  font-size: 16px;
}

.title-data-integration {
  font-size: 12px;
  font-weight: 700;
  color: #2B2E33;
  text-transform: uppercase;
}
.content-data-integration {
  font-size: 16px;
  font-weight: 400;
  color: #2B2E33
}

.projects-list-view-container {
  .progress {
    height: 7px;
  }
  .table td, .table th {
    padding: 1rem 1.5rem !important;
  }
  .table td:first-child {
    padding-right: 0 !important;
  }
  .amount-tco2e-integrations {
    font-size: 15px;
    font-weight: 500 !important;
    color: #262626
  }
  .amount-tco2e-integrations-unit {
    color: #959699;
    font-size: 15px;
    font-weight: 400 !important;
  }
}
.table-projects-integrations {
  max-height: 350px;
  overflow-y: auto;
}
.integrations-dashboard-container {
 .card2 {
   margin-top: 0 !important;
   margin-bottom: 2em !important;
 }
}

.integrations-cart {
  .checkoutCard {
    flex-wrap: unset;

    .page-title__text {
      min-width: 100%;
    }

    .page-title {
      margin: 0 !important;
      padding: 0 !important;
      min-width: 100%;
    }
    h1.title {
      margin-top: 0;
      padding: 0.8em 1.2em;
      border-bottom: 1px solid #E0E4E7;
      color: $primary;
      border-radius: 0;
      font-weight: 600;
      font-size: 18px !important;
      width: 100%;
    }
    .paymentTextSelected, .paymentText {
      font-size: 16px;
    }
    .paymentMethod {
      margin: 1.25rem 1.5rem !important;
      border: 0 !important;
      .balance {
        color: $primary
      }
      &:nth-child(2n) {
        margin-top: 0.25rem !important;
      }
      &:last-child {
        margin-bottom: 1.75rem !important;
      }
    }
    .paymentMethods {
      .border-non-selected {
        background-color: #F8F8F9;
        padding: 1.1rem
      }
      .border-selected {
        background-color: #E6F9FF;
        padding: 1.1rem
      }
    }
  }
  .paymentMethods {
    width: 100% !important;
    background-color: #fff;
    border: 1px solid #E0E4E7;
    border-radius: 10px;
    margin-right: 0.75rem;

    .buttonsToPay {
      display: none !important;
    }
  }
  .resumeOrder {
    margin-left: 0.75rem;
    width: 100%;
    background-color: #fff;
    border: 1px solid #E0E4E7;
    border-radius: 10px;

    .cart-button {
      display: none !important;
    }
    .card {
      border: 0
    }
  }
}

.integrations-dashboard-container-wes-design {
  .value-media {
    display: none !important;
  }
  .has-text-grey {
    display: none !important;
  }
  .dataValueTitle {
    font-size: 24px;
    display: flex;
    .has-text-grey {
      display: block !important;
      margin-left: 5px;
      color: $primary
    }
  }
  hr {
    display: none;
  }
  .data-resume-block-active {
    background-color: #F6FBFD !important;
    border-radius: 10px !important;
  }
  .data-resume-block {
    background-color: #FBFBFC !important;
    border-radius: 10px !important;
    width: 100% !important;
    &:last-child {
      margin-right: 0;
    }
  }
  .data-resume-block:hover {
    transform: unset;
    box-shadow: unset;
    transition: unset;
  }
}

.back-text {
  font-size: 15px;
  font-weight: 600;
  color: #2B2E33;
}
