.new-blue {
  font-size: 1.01em;
  hr {
    height: 1px;
  }
   img {
     filter: saturate(120%);
   }
  .label {
    font-size: 1rem;
    font-weight: 500;
  }
  .amount-balance {
    display:flex;
    justify-content: space-between;
    font-weight: 600;
    text-transform: uppercase;
  }
  .project-detail-amount {
    color: $primary;
    font-weight: 600
  }
  .card-image {
    margin: -1em;
  }
  .card-image-project-detail {
    margin: 0 !important;
    .carousel-item {
      img {
        border-radius: 4px !important;
      }
    }
  }
  .filterSelect {
    border: 0;
    border-radius: 4px
  }
 .search {
    border-radius: 4px;
  }
  td {
    font-weight: 500;
  }
  .top-projects-table {
    border-top: 0
  }
  .data_value {
    font-size: 18px;
  }
  .elements {
    font-size: 1.03em;
  }
  .filterItem {
    font-size: 1.03em !important;
  }
  .button {
    border-radius: 4px !important;
  }
  .button.is-primary {
    background-color: $primary;
    border-color: $primary;
    color: white;
  }
  .button.is-primary:hover {
    transition: 300ms;
    background-color: #004e92 !important;
    border-color: #004e92 !important;
    color: #fff
  }
  .button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
  }
  .input-wrapper {
    border-radius: 4px;
  }

  .hr-small {
    margin: 0.45rem 0
  }
  .rowTransaction {
    background-color: white !important
  }


  .my-container {
    height: auto;
    min-height: 100%;
  }
  form {
    background: transparent !important;
    .field {
    background: transparent !important;
      .control {
        background: transparent !important;
      }
    }
  }
  .icon {
    g, path {
      fill: #b2b9bf;
    }
  }
  .title {
    color: #404f56
  }
  h1 {
    &.title {
      color: #404f56
    }
  }
  .columnTitle {
    font-weight: 700 !important;
    font-size: 1.05em !important;
    p {
      font-weight: 700 !important;
      font-size: 1.05em !important;
    }
  }
  .messageViewRow {
    .fields {
      font-size: 1.03em;
      color: #31343b;
      p {
        font-size: 1.03em;
        color: #31343b
      }
    }
  }
  .notification-circle {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #ED5F55;
    margin-right: 9px;
    margin-left: 9px;
  }
  .notification-empty {
    width: 24px;
  }
  .button.is-primary.is-outlined {
    background-color: transparent;
    border-color: $primary;
    color: $primary;
  }
  .project-detail-purchase-card {
    input {
      border-color: #95CDDF;
    }
    background-color: #E6F9FF !important;
    .is-outlined {
      background-color: transparent !important;
      color: $primary;
    }
    .total-order {
      font-weight: 700;
      font-size: 1.03rem;
      margin: 0 -1.25rem 1.25rem;
      margin-bottom: 0;
    }
  }
  .project-detail-purchase-card.card {
      border: 1px solid;
      border-color: #B5DCE8;
  }
  .cart-index {
    .columnTitle {
      color: #404f56 !important;
    }
    .messageViewRow {
      .fields {
        color: #404f56 !important ;
      }
    }
  }
  .card-content {
    padding: 1.25rem;
  }
  hr {
    margin: 1.25rem 0;
  }
  .table td, .table th {
    padding: 1.25rem 1.25rem;
  }
}
